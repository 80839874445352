import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Card, CardTitle, Col, Row } from "reactstrap"

const HorizontalCardBlock = props => {
  return (
    <>
      <Card className="card-plain card-blog">
        <Row>
          <Col md="4" className="d-flex">
            <div className="d-flex w-100 card-image">
              <Img
                fluid={props.image}
                alt={props.title}
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
                className="img img-raised rounded"
              />
            </div>
          </Col>
          <Col md="8">
            <CardTitle tag="h3" className="text-center text-md-left">
              <Link
                to={props.link}
                title={props.title}
                style={{ color: "#7AB1C3" }}
              >
                {props.title}
              </Link>
            </CardTitle>
            <p className="card-description text-center text-md-left">
              {props.description}
            </p>
            <div className="link text-center text-md-left">
              <Link
                to={props.link}
                title={props.title}
                className="btn btn-round"
              >
                <i className="now-ui-icons objects_umbrella-13 pr-2"></i>
                {props.ctaText}
              </Link>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  )
}

HorizontalCardBlock.defaultProps = {
  ctaText: "Zobacz apartament",
}

export default HorizontalCardBlock
